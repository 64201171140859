.diceImage {
    position: relative;
    text-align: center;
}

.diceText {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
}
